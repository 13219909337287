<template>
  <a-modal
    width="60%"
    title="服务详情"
    :visible="open"
    :confirm-loading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="20">
            <a-col :md="10" :sm="24">
              <a-form-item label="后巷屋id" prop="lanewayHousesId">
                <a-input v-model="queryParam.lanewayHousesId" placeholder="请输入后巷屋id" allow-clear />
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="名称" prop="name">
                  <a-input v-model="queryParam.name" placeholder="请输入名称" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="价格" prop="money">
                  <a-input v-model="queryParam.money" placeholder="请输入价格" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="顺序" prop="sort">
                  <a-input v-model="queryParam.sort" placeholder="请输入顺序" allow-clear />
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['laneway:housesServe:add']">
          <a-icon type="plus" />新增
        </a-button>
        <a-button
          type="primary"
          :disabled="single"
          @click="$refs.createForm.handleUpdate(undefined, ids)"
          v-hasPermi="['laneway:housesServe:edit']"
        >
          <a-icon type="edit" />修改
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['laneway:housesServe:remove']">
          <a-icon type="delete" />删除
        </a-button>
        <a-button type="primary" @click="handleExport" v-hasPermi="['laneway:housesServe:export']">
          <a-icon type="download" />导出
        </a-button>
        <table-setting
          :style="{ float: 'right' }"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList"
        />
      </div>
      <!-- 增加修改 -->
      <!-- <create-form ref="createForm" @ok="getList" /> -->
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        rowKey="id"
        :columns="columns"
        :data-source="data.records"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
       
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['laneway:housesServe:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['laneway:housesServe:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['laneway:housesServe:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['laneway:housesServe:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="data.total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => `共 ${data.records.length} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>

    <edit-form ref="createForm" @ok="getList" />
  </a-modal>
</template>

<script>
import EditForm from './EditForm.vue'
import { getHouses, addHouses, updateHouses } from '@/api/laneway/houses'
import Editor from '@/components/Editor'
import { pageHousesServe, listHousesServe, delHousesServe } from '@/api/laneway/housesServe'
// import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
export default {
  name: 'SrarchForm',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    Editor,
    EditForm,
  },
  data() {
    return {
      previewVisible: false,
      previewImage: '',
      fileList: [],
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        cover: null,

        videoUrl: null,

        picUrl: null,

        deposit: null,

        estimatedPrice: null,

        title: null,

        content: null,

        createTime: null,

        remark: null,
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        cover: [{ required: true, message: '封面不能为空', trigger: 'blur' }],
        videoUrl: [{ required: true, message: '视频地址不能为空', trigger: 'blur' }],
        picUrl: [{ required: true, message: '图片地址,多个使用逗号隔开不能为空', trigger: 'blur' }],
        deposit: [{ required: true, message: '定金不能为空', trigger: 'blur' }],
        estimatedPrice: [{ required: true, message: '预估价格不能为空', trigger: 'blur' }],
        title: [{ required: true, message: '标题不能为空', trigger: 'blur' }],
        content: [{ required: true, message: '内容不能为空', trigger: 'blur' }],
      },
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        lanewayHousesId: null,
        type: null,
        name: null,
        money: null,
        sort: null,
        pageNum: 1,
        pageSize: 10,
      },
      columns: [
        {
          title: '主键',
          dataIndex: 'id',
          ellipsis: true,
          align: 'center',
        },
        // {
        //   title: '后巷屋id,外键{zb_laneway_houses.id}',
        //   dataIndex: 'lanewayHousesId',
        //   ellipsis: true,
        //   align: 'center',
        // },
        // {
        //   title: '服务类型,0-方案,1-施工',
        //   dataIndex: 'type',
        //   ellipsis: true,
        //   align: 'center',
        // },
        {
          title: '名称',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center',
        },
        {
          title: '价格',
          dataIndex: 'money',
          ellipsis: true,
          align: 'center',
        },
        {
          title: '顺序',
          dataIndex: 'sort',
          ellipsis: true,
          align: 'center',
        },
        {
          title: '插入时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center',
        },
        // {
        //   title: '备注',
        //   dataIndex: 'remark',
        //   ellipsis: true,
        //   align: 'center',
        // },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center',
        },
      ],
    }
  },
  filters: {},
  created() {},
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    onClose() {
      this.open = false
    },
    onOpen() {
      this.open = true
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        cover: null,
        videoUrl: null,
        picUrl: null,
        deposit: null,
        estimatedPrice: null,
        title: null,
        content: null,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        if (this.$refs.form) {
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getHouses({ id: id }).then((response) => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateHouses(this.form)
              .then((response) => {
                this.$message.success('修改成功', 3)
                this.open = false
                this.$emit('ok')
              })
              .finally(() => {
                this.submitLoading = false
              })
          } else {
            addHouses(this.form)
              .then((response) => {
                this.$message.success('新增成功', 3)
                this.open = false
                this.$emit('ok')
              })
              .finally(() => {
                this.submitLoading = false
              })
          }
        } else {
          return false
        }
      })
    },
    //图片上传
    imageChange(e) {
      //  console.log(e)
      this.form.cover = e[0].path
    },
    videoChange(e) {
      console.log(e)
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    handleChange({ fileList }) {
      this.fileList = fileList
    },
    handleCancel() {
      this.previewVisible = false
    },
    /**视频上传成功后的回调 */
    getVideo(info, fieldName) {
      console.log('视频上传成功后的回调数据 info', info)
      if (info.length === 0) {
        this.form[fieldName] = ''
        this.form[`${fieldName}Arr`] = []
      } else {
        if (this.form[`${fieldName}Count`] === 1) {
          this.form[fieldName] = info[0].halfUrl
          this.form[`${fieldName}Arr`] = info
        } else {
          this.form[fieldName] = info
            .map((ele) => {
              return ele.halfUrl
            })
            .join(',')
          this.form[`${fieldName}Arr`] = info
        }
      }
    },
    /**上传图片成功回调 */
    getImg(info, fieldName) {
      console.log('info', info)
      if (info.length === 0) {
        this.form[fieldName] = ''
        this.form[`${fieldName}Arr`] = []
      } else {
        if (this.form[`${fieldName}Count`] === 1) {
          this.form[fieldName] = info[0].url
          this.form[`${fieldName}Arr`] = info
        } else {
          this.form[fieldName] = info
            .map((ele) => {
              return ele.url
            })
            .join(',')
          this.form[`${fieldName}Arr`] = info
        }
      }
    },
    //////////////////////////////////////////////
    /** 查询后巷屋服务列表 */
    getList() {
      this.loading = true
      pageHousesServe(this.queryParam).then((response) => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        lanewayHousesId: undefined,
        type: undefined,
        name: undefined,
        money: undefined,
        sort: undefined,
        pageNum: 1,
        pageSize: 10,
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          let data = []
          that.ids.map((id) => data.push({ id: id }))
          return delHousesServe(data).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {
          //取消清空删除ids列表
          that.ids = []
        },
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          that.download(
            'laneway/lanewayHousesServe/export',
            {
              ...that.queryParam,
            },
            `后巷屋服务_${new Date().getTime()}.xlsx`
          )
        },
        onCancel() {},
      })
    },

    handleOk(e) {
      this.open = false
    },
    handleCancel(e) {
      this.open = false
    },
  },
}
</script>
